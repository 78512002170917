.main-layout {
  min-height: 100vh;
  background: #9fceee !important;
  .ant-layout-header {
    background-color: transparent !important;
    padding: 0 24px;
    line-height: unset !important;
    display: flex;
    align-items: center;
    height: 64px;
    width: 100%;
    .ant-input-affix-wrapper {
      width: 40%;
      margin-left: 40px;
      height: 36px;
      border-radius: 14px;
      border: none;
      .anticon {
        font-size: 22px;
        color: #b3b1b1;
      }
    }
    .data-panel {
      border-radius: 16px;
      background: #5a85cc !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 24px;
      margin-left: 16px;
      cursor: pointer;
    }
    .header-right {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .bell-icon {
        font-size: 28px;
        color: red;
        cursor: pointer;
      }
      .ant-badge-count {
        top: 14px;
        right: 14px;
        background: transparent;
        border: none;
        box-shadow: none;
        font-weight: bold;
        font-size: 15px;
      }
      .profile-image-wrap {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin-left: 20px;
        margin-right: 12px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
    }
  }
  .ant-layout-has-sider {
    background: #9fceee;
    .ant-layout-sider {
      background: transparent !important;
      .logo {
        margin-top: -60px;
        margin-left: 30px;
      }
    }
    .ant-layout {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #fff;
    }
    .ant-layout-sider-collapsed {
      .logo {
        display: none;
      }
    }
  }
  .ant-layout-sider {
    .ant-menu {
      background: #9fceee !important;
      .ant-menu-item {
        font-size: 13px;
        margin-bottom: 2px;
        margin-top: 0;
        svg {
          color: #808080;
          font-size: 18px;
        }
      }
      .ant-menu-item-selected {
        color: #fff;
        background: #3e4095 !important;
        svg {
          color: #fff;
        }
      }
      .ant-menu-item::after {
        border-right-color: #5a85cc;
      }
    }
    .ant-layout-sider-trigger {
      background: #5a85cc !important;
    }
  }
}
