.ant-drawer-content {
  background-color: #bdbfc1;
  .ant-drawer-header {
    background-color: transparent;
    border-bottom: unset;
    .ant-drawer-title {
      color: #fff;
    }
  }
  .side-panel-wraper {
    .graphs-wraper {
      padding: 20px 0px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 20px;
      align-items: center;
      .single-graph {
        cursor: pointer;
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        height: 124px;
        width: 124px;
        border-radius: 10px;
        background-color: #7e7fd6;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .single-graph:nth-child(1) {
        background-color: #7eb89e;
      }
    }
    .ant-carousel {
      margin-bottom: 20px;
      .single-graph {
        cursor: pointer;
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        height: 124px;
        width: 124px;
        border-radius: 10px;
        background-color: #7eb89e;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .slick-dots-bottom {
        bottom: -40px !important;
        li {
          background-color: #373435 !important;
        }
        .slick-active {
          button {
            background-color: #ed3237 !important;
          }
        }
      }
    }
    .left-arrow-carosel {
      position: absolute;
      z-index: 10;
      top: 40%;
      left: -35px;
      .anticon {
        font-size: 40px;
        color: #686a6b;
        cursor: pointer;
      }
    }
    .right-arrow-carosel {
      position: absolute;
      z-index: 10;
      top: 40%;
      right: -25px;
      .anticon {
        font-size: 40px;
        color: #686a6b;
        cursor: pointer;
      }
    }

    .divider {
      border-color: #ccc;
    }
    .short {
      display: flex;
      .short-data {
        flex-basis: 40%;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
      }
      .ant-select {
        flex-basis: 50%;
        text-align: center;
        color: #373435;
        margin: auto;
        border: none;
        .ant-select-selector {
          border-radius: 10px;
        }
        .ant-select-selection-placeholder {
          color: #000;
        }
      }
    }
    .export {
      display: flex;
      span {
        flex-basis: 40%;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
      }
      select {
        height: 28px;
        flex-basis: 50%;
        text-align: center;
        color: #373435;
        margin: auto;
        border: none;
        border-radius: 10px;
      }
    }
    .view-table {
      display: flex;
      span {
        flex-basis: 40%;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
      }
      .button {
        display: flex;
        margin: 0 auto;
        flex-basis: 50%;
        .active-switch {
          background-color: #3e4095 !important;
          color: #fff !important;
          cursor: default !important;
        }
        .off {
          width: 100px;
          height: 28px;
          background-color: #fff;
          text-align: center;
          line-height: 27px;
          border-radius: 10px 0px 0px 10px;
          cursor: pointer;
        }
        .on {
          color: #373435;
          width: 100px;
          height: 28px;
          background-color: #fff;
          text-align: center;
          line-height: 28px;
          border-radius: 0px 10px 10px 0px;
          cursor: pointer;
        }
      }
    }
    .trend-action {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
    .schedule {
      margin-top: 15px;
      .line-1 {
        display: flex;
        margin-bottom: -10px;
        span {
          flex-basis: 34%;
          height: 28px;
          background-color: #3e4095;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
        p {
          width: 28px;
          height: 28px;
          background-color: #ed3237;
          font-weight: bold;
          text-align: center;
          line-height: 28px;
          color: #fff;
        }
        .color-code {
          width: 100%;
          flex-basis: 30%;
          height: 28px;
          background-color: #fff;
          line-height: 28px;
          text-align: center;
        }
        .color-1 {
          width: 100%;
          flex-basis: 29%;
          height: 28px;
          background-color: #fcd3c2;
          margin-left: 4px;
        }
      }
      .line-2 {
        display: flex;
        justify-content: end;
        p {
          width: 28px;
          height: 28px;
          background-color: #ed3237;
          font-weight: bold;
          text-align: center;
          line-height: 28px;
          color: #fff;
        }
        .color-code {
          width: 100%;
          flex-basis: 30%;
          height: 28px;
          background-color: #fff;
          line-height: 28px;
          text-align: center;
        }
        .color-1 {
          width: 100%;
          flex-basis: 29%;
          height: 28px;
          background-color: #fcd3c2;
          margin-left: 4px;
        }
      }
      .pie {
        .pie-1 {
          display: flex;
          margin-bottom: -10px;
          span {
            flex-basis: 34%;
            height: 28px;
            background-color: #3e4095;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
          p {
            width: 28px;
            height: 28px;
            background-color: #ed3237;
            font-weight: bold;
            text-align: center;
            line-height: 28px;
            color: #fff;
          }
          .color-code {
            width: 100%;
            flex-basis: 30%;
            height: 28px;
            background-color: #fff;
            line-height: 28px;
            text-align: center;
          }
          .color-1 {
            width: 100%;
            flex-basis: 29%;
            height: 28px;
            background-color: #fcd3c2;
            margin-left: 4px;
          }
        }
        .pie-2 {
          display: flex;
          justify-content: end;
          margin-bottom: -10px;

          p {
            width: 28px;
            height: 28px;
            background-color: #ed3237;
            font-weight: bold;
            text-align: center;
            line-height: 28px;
            color: #fff;
          }
          .color-code {
            width: 100%;
            flex-basis: 30%;
            height: 28px;
            background-color: #fff;
            line-height: 28px;
            text-align: center;
          }
          .color-1 {
            width: 100%;
            flex-basis: 29%;
            height: 28px;
            background-color: #fcd3c2;
            margin-left: 4px;
          }
        }
        .pie-3 {
          display: flex;
          justify-content: end;
          margin-bottom: -10px;

          p {
            width: 28px;
            height: 28px;
            background-color: #ed3237;
            font-weight: bold;
            text-align: center;
            line-height: 28px;
            color: #fff;
          }
          .color-code {
            width: 100%;
            flex-basis: 30%;
            height: 28px;
            background-color: #fff;
            line-height: 28px;
            text-align: center;
          }
          .color-1 {
            width: 100%;
            flex-basis: 29%;
            height: 28px;
            background-color: #fcd3c2;
            margin-left: 4px;
          }
        }
        .pie-4 {
          display: flex;
          justify-content: end;
          margin-bottom: -10px;

          p {
            width: 28px;
            height: 28px;
            background-color: #ed3237;
            font-weight: bold;
            text-align: center;
            line-height: 28px;
            color: #fff;
          }
          .color-code {
            width: 100%;
            flex-basis: 30%;
            height: 28px;
            background-color: #fff;
            line-height: 28px;
            text-align: center;
          }
          .color-1 {
            width: 100%;
            flex-basis: 29%;
            height: 28px;
            background-color: #fcd3c2;
            margin-left: 4px;
          }
        }
      }
    }
    .apply-btn {
      font-size: 20px;
      font-weight: bold;
      width: 80%;
      height: 40px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #ff0000;
      margin: 15px auto 0;
      cursor: pointer;
    }
  }
}
